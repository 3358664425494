import React from 'react';
import styled, { css } from 'styled-components';

import { LeftArrowSvg, RightArrowSvg } from 'Assets/svg/productPage';

import colors from 'Static/typography/colors';

const CoreCarouselWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 1);

	border-radius: 0.5rem;
`;

const SlideWrapper = styled.div<{ isVisible?: boolean }>`
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	right: unset;
	bottom: unset;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: ${colors.GREY.LIGHTEST};
	opacity: ${props => ((props as any).isVisible ? 1 : 0)};
	border-radius: 0.5rem;
	transition: opacity 1s ease !important;

	.core-carousel-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const Indicator = styled.div`
	position: absolute;
	width: 100%;
	bottom: 1rem;
	z-index: 2;
	text-align: center;
`;

const Dot = styled.div<{ isActive?: boolean }>`
	display: inline-block;
	margin: 0.3125rem;
	width: 0.625rem;
	height: 0.625rem;
	background: ${props =>
		(props as any).isActive ? colors.WHITE : colors.WHITE_DOT3};
	cursor: pointer;
	border-radius: 50%;
	transition: all 0.7s ease !important;
`;

const modalCarouselArrow = css`
	width: 1.875rem;
	height: 1.875rem;
	background-color: ${colors.WHITE};
	top: 12.5rem;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
`;

export const ArrowButton = styled.button<{
	left?: boolean;
	size?: string;
	posX?: string;
	posY?: string;
	isModalCarousel?: boolean;
	right?: boolean;
}>`
	background-color: ${colors.WHITE};
	position: absolute;
	top: ${props => props.posY || '50%'};
	transform: translateY(${props => (props.posY ? `-${props.posY}` : '-50%')});
	width: ${props => props.size || '2.5rem'};
	height: ${props => props.size || '2.5rem'};
	border-radius: 1.875rem;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	box-shadow: rgb(0 0 0 / 15%) 0px 2px 5px 0px;

	${({ left, isModalCarousel, posX }) => {
		if (isModalCarousel) {
			return left
				? `
						left: -1.6em;
						${modalCarouselArrow}
				  `
				: `
						right: -1.6em;
						${modalCarouselArrow}
				  `;
		}
		return left
			? `
					left: ${posX || '1.6em'};
			  `
			: `
					right: ${posX || '1.6em'};
			  `;
	}};

	svg {
		width: 40%;
		height: 40%;
		fill: ${(colors.GREY as any).DARK};
		stroke: ${(colors.GREY as any).DARK};
		transform: translateX(
			${props => ((props as any).left ? '-0.5px' : '0.5px')}
		);
	}
`;

type OwnProps = {
	defaultIndex?: number;
	defaultTransitionDuration?: number;
	onChange?: (...args: any[]) => any;
	isMobile?: boolean;
	children: any;
};

type State = any;

type Props = OwnProps & typeof Carousel.defaultProps;

class Carousel extends React.PureComponent<Props, State> {
	static defaultProps = {
		defaultIndex: 0,
		defaultTransitionDuration: 5000,
	};

	state = {
		activeIndex: 0,
	};

	componentDidMount() {
		this.resetSlideshow();
	}

	componentWillUnmount() {
		window.clearInterval((this as any).slideshowInterval);
	}

	onPreviousClick = () => {
		const length =
			(this.props.children as any).length ||
			(this.props.children as any).length;
		const prevIndex = (this.state.activeIndex - 1) % length;
		this.changeActiveIndex(prevIndex < 0 ? length - 1 : prevIndex, true);
	};

	onNextClick = () => {
		const length =
			(this.props.children as any).length ||
			(this.props.children as any).length;
		this.changeActiveIndex((this.state.activeIndex + 1) % length, true);
	};

	resetSlideshow = () => {
		window.clearInterval((this as any).slideshowInterval);
		(this as any).slideshowInterval = window.setInterval(() => {
			const length =
				(this.props.children as any).length ||
				(this.props.children as any).length;
			this.changeActiveIndex((this.state.activeIndex + 1) % length);
		}, this.props.defaultTransitionDuration);
	};

	// @ts-expect-error TS(7006): Parameter 'activeIndex' implicitly has an 'any' ty... Remove this comment to see the full error message
	changeActiveIndex = (activeIndex, deliberate = false) => {
		this.setState({ activeIndex });
		if (this.props.onChange) this.props.onChange(activeIndex, deliberate);
	};

	render() {
		const { children, isMobile } = this.props;
		return (
			<CoreCarouselWrapper>
				{(children as any).map((c: any, i: any) => {
					const relIndex = i - this.state.activeIndex;
					return (
						<SlideWrapper isVisible={relIndex === 0} key={i}>
							{c}
						</SlideWrapper>
					);
				})}
				{(children as any).length > 1 ? (
					<Indicator>
						{/* @ts-expect-error TS(6133): 'c' is declared but its value is never read. */}
						{(children as any).map((c, i) => {
							const relIndex = i - this.state.activeIndex;
							return (
								<Dot
									isActive={relIndex === 0}
									key={i}
									onClick={() => {
										if (this.state.activeIndex !== i) {
											this.changeActiveIndex(i, true);
										}
									}}
								/>
							);
						})}
					</Indicator>
				) : null}
				{(children as any).length > 1 && !isMobile ? (
					<ArrowButton onClick={this.onPreviousClick} left>
						<LeftArrowSvg />
					</ArrowButton>
				) : null}
				{(children as any).length > 1 && !isMobile ? (
					<ArrowButton onClick={this.onNextClick} right>
						<RightArrowSvg />
					</ArrowButton>
				) : null}
			</CoreCarouselWrapper>
		);
	}
}

export default Carousel;
