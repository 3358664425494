import { useSelector } from 'react-redux';

import { getCurrentLanguageCode } from 'Utils/stateUtils';

import { RTL_LANGUAGE_CODES } from 'Constants/constants';

const useRtl = (rtlOverride: boolean | undefined) => {
	const lang = useSelector(state => getCurrentLanguageCode(state));
	if (rtlOverride != null) return rtlOverride;
	else return RTL_LANGUAGE_CODES.includes(lang);
};

export default useRtl;
